.intake-form {
  label {
    font-size: 1.0rem;
    color: rgb(28, 27, 27);
  }

  .form-check-input {
    margin: 0 0.5em;
    vertical-align: middle;
  }
}