.header-title {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;

  h3 {
    flex-grow: 1;
  }

  button {
    margin-left: 20px;
  }
}