.navbar {
  // main logo height/width
  svg {
    height: 30px;
    width: 69px;
  }
}


.navbar-nav {
  .nav-item {
    .nav-link {
      &:hover {
        color:#99fc86 !important;
      }
      &.active {
        color:#99fc86 !important;
      }
    }
  }
}