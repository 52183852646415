.dropzone {
  text-align: center;
  padding: 30px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  cursor: pointer;
  margin-bottom: 20px;
}

.selected-file-wrapper {
  text-align: center;
}

.selected-file {
  color: #000;
  font-weight: bold;
}

.thumbnails-container {
  justify-content: left; /* Center the images horizontally */
  align-items: center; /* Center the images vertically */
  padding: 20px;
  background-color: #f0f0f0;
}

.thumbnail {
  object-fit: cover; /* Maintain aspect ratio and fill the container */
  margin-right: 10px; /* Add some space between images */
  border: 1px solid #ccc; /* Add a border to the thumbnails */
  border-radius: 5px; /* Round the corners of the thumbnails */
}

/* Remove the margin-right from the last thumbnail to avoid extra spacing */
.thumbnail:last-child {
  margin-right: 0;
}