.radio-button-card {
  cursor: pointer;

  .muted {
    opacity: 0.4;
  }
  .card-item {
    padding: 0;

    .card-item:not(.muted):hover {
      border: 3px solid #98FC85;
    }

    &.active {
      border: 3px solid #98FC85;
    }

    .top-info {
      border: 1px solid #ebeff2;
      border-radius: 5px;
      padding: 20px 0px;
      margin: 10px 20px;
      font-size: 16px;
      color: #5aadef;
      font-weight: 600;
      overflow: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      .left {
        border-right: 1px solid grey;
        padding-right: 1.5rem;
      }
    }
    .top-info div {
      float: left;
      width: 50%;
      text-align: center;
      position: relative;
      vertical-align: middle;
    }

    .stats {
      border-top: 1px solid #ebeff2;
      background: #f7f8fa;
      overflow: auto;
      padding: 15px 0;
      font-size: 16px;
      color: #59687f;
      font-weight: 600;
      border-radius: 0 0 5px 5px;
    }
    .stats div {
      border-right: 1px solid #ebeff2;
      min-width: 50%;
      text-align: center;
    }

    .stats div:nth-of-type(3) {
      border: none;
    }

    .accordion-button {
      background-color: #98FC85;
    }
  }
}
