.checkin-form, .client-feedback-form {
  .form-check {
    padding-left: 0;
  }

  label {
    font-size: 1.0rem;
    color: rgb(28, 27, 27);
  }

  .form-check-input {
    margin: 0 0.5em;
    vertical-align: middle;

    &:checked {
      background-color: #99fc86;
      border: #99fc86;
    }
  }

  .range-slider.range-slider--success::-webkit-slider-thumb {
    background: #99fc86 !important;
  }
}