.login-block {
  background: #01060f; /* fallback for old browsers */
  //background: -webkit-linear-gradient(to bottom, #ace9ae, #01060f);  /* Chrome 10-25, Safari 5.1-6 */
  //background: linear-gradient(to bottom, #ace9ae, #01060f); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  float: left;
  width: 100%;
  height: 100%;
  padding: 50px 0;

  .banner-sec {
    background-size: cover;
    min-height: 500px;
    border-radius: 0 10px 10px 0;
    padding: 0;
  }
  .container {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 8px #667366;
  }
  .carousel-inner {
    border-radius: 0 10px 10px 0;
  }
  .carousel-caption {
    text-align: left;
    left: 5%;
  }
  .login-sec {
    padding: 50px 30px;
    position: relative;
  }
  .login-sec .copy-text {
    position: absolute;
    width: 80%;
    bottom: 20px;
    font-size: 13px;
    text-align: center;
  }
  .login-sec .copy-text i {
    color: #ace9ae;
  }
  .login-sec .copy-text a {
    color: #ace9ae;
  }
  .login-sec h2 {
    margin-bottom: 30px;
    font-weight: 800;
    font-size: 30px;
    color: #ace9ae;
  }
  .login-sec h2:after {
    content: " ";
    width: 100px;
    height: 5px;
    background: #ace9ae;
    display: block;
    margin-top: 20px;
    border-radius: 3px;
    margin-left: auto;
    margin-right: auto;
  }
  .btn-login {
    background: #ace9ae;
    color: #fff;
    font-weight: 600;
  }
  .banner-text {
    width: 70%;
    position: absolute;
    bottom: 40px;
    padding-left: 20px;
  }
  .banner-text h2 {
    color: #fff;
    font-weight: 600;
  }
  .banner-text h2:after {
    content: " ";
    width: 100px;
    height: 5px;
    background: #fff;
    display: block;
    margin-top: 20px;
    border-radius: 3px;
  }
  .banner-text p {
    color: #fff;
  }
}
