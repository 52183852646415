.data-card {
  width: 100%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 3px -1px rgba(151, 171, 187, 0.7);
  float: left;
  transform-style: preserve-3d;
  margin-bottom: 1.5rem;

  .carousel-inner {
    height: 200px;
  }

  .header {
    border-bottom: 1px solid #ebeff2;
    padding: 19px 0;
    text-align: center;
    color: white;
    font-size: 600;
    font-size: 19px;
    position: relative;
    background-color: #99fc86;
  }

  .banner-img {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .banner-img img {
    width: 100%;
    border-radius: 5px;
  }

  .top-info {
    border: 1px solid #ebeff2;
    border-radius: 5px;
    padding: 20px 0px;
    margin: 10px 20px;
    font-size: 16px;
    color: #5aadef;
    font-weight: 600;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .left {
      border-right: 1px solid grey;
      padding-right: 1.5rem;
    }
  }
  .top-info div {
    float: left;
    width: 50%;
    text-align: center;
    position: relative;
    vertical-align: middle;
  }
  .top-info strong,
  .stats strong {
    display: block;
    color: #adb8c2;
    font-size: 11px;
    font-weight: 700;
  }

  .stats {
    border-top: 1px solid #ebeff2;
    background: #f7f8fa;
    overflow: auto;
    padding: 15px 0;
    font-size: 16px;
    color: #59687f;
    font-weight: 600;
    border-radius: 0 0 5px 5px;
  }
  .stats div {
    border-right: 1px solid #ebeff2;
    width: 33.33333%;
    float: left;
    text-align: center;
  }

  .stats div:nth-of-type(3) {
    border: none;
  }

  div.footer {
    text-align: right;
    position: relative;
    margin: 20px 5px;
  }

  div.footer a.Cbtn {
    padding: 10px 25px;
    background-color: #dadada;
    color: #666;
    margin: 10px 2px;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    border-radius: 3px;
  }

  div.footer a.Cbtn-primary {
    background-color: #5aadf2;
    color: #fff;
  }

  div.footer a.Cbtn-primary:hover {
    background-color: #7dbef5;
  }

  div.footer a.Cbtn-danger {
    background-color: #fc5a5a;
    color: #fff;
  }

  div.footer a.Cbtn-danger:hover {
    background-color: #fd7676;
  }
}
