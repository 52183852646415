.superhuman-pagination .page-link {
  border-color: #99fc86;
  color: grey;
}

.superhuman-pagination .page-item.active .page-link {
  background-color: #99fc86;
  color: white;
}

.superhuman-pagination .page-link:hover {
  color: white;
  background-color: #99fc86;
  border-color: #99fc86;
}