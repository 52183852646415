
.grid figure {
  position: relative;
  float: left;
  overflow: hidden;
  background: #3085a3;
  text-align: center;
  cursor: pointer;
}

.grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
  margin: 0;
}

.grid figure figcaption {
  padding: 2em;
  color: #ACE9AD;
  text-shadow: white 0px 0px 10px;

  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
  pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption>a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.grid figure figcaption>a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.grid figure h2 {
  word-spacing: -0.15em;
  font-weight: 300;
}

.grid figure h2 span {
  font-weight: 800;
}

.grid figure h2,
.grid figure p {
  margin: 0;
}

.grid figure p {
  letter-spacing: 1px;
  font-size: 68.5%;
}



figure.effect-ravi {
  background: #303fa9;
  margin: 0;
}

.grid [class^="col"] {
  padding: 0.75rem;
}

figure.effect-ravi h2 {
  font-size: 158.75%;
}

figure.effect-ravi h2,
figure.effect-ravi p {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
}

figure.effect-ravi figcaption::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120px;
  height: 120px;
  border: 2px solid #fff;
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg) scale3d(0, 0, 1);
  transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg) scale3d(0, 0, 1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
}

figure.effect-ravi p {
  width: 100px;
  text-transform: none;
  font-size: 121%;
  line-height: 2;
}

figure.effect-ravi .logo {
  color: #fff;
}

figure.effect-ravi img:hover,
figure.effect-ravi p a:focus {
  opacity: 0.6;
}

figure.effect-ravi .logo {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-ravi .logo{
  -webkit-transform: translate3d(-60px, -60px, 0);
  transform: translate3d(-60px, -60px, 0);
}

figure.effect-ravi:hover figcaption::before {
  opacity: 1;
  -webkit-transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg) scale3d(1, 1, 1);
  transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg) scale3d(1, 1, 1);
}

figure.effect-ravi:hover h2 {
  opacity: 0;
  -webkit-transform: translate3d(-50%, -50%, 0) scale3d(0.8, 0.8, 1);
  transform: translate3d(-50%, -50%, 0) scale3d(0.8, 0.8, 1);
}

figure.effect-ravi:hover .logo:empty {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  /* just because it's stronger than nth-child */
  opacity: 1;
}